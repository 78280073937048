$ArtOnU: "../assets/icons/ArtOnUv2.svg";
$AboutUs: "../assets/icons/AboutUs.svg";
$ArtFans: "../assets/icons/ArtFans.svg";
$ComingSoon: "../assets/icons/ComingSoon.svg";
$NewArrivals: "../assets/icons/NewCollections.svg";
$Ups: "../assets/icons/Ups.svg";
$WeCreateToBeYourself: "../assets/icons/WeCreateToBeYourself.svg";
$VISA: "../assets/icons/VISA.svg";
$Mastercard: "../assets/icons/Mastercard.svg";
$PayPal: "../assets/icons/PayPal.svg";
$OXXO: "../assets/icons/OXXO.svg";
.NoRepeat {
  background-repeat: no-repeat;
}
.ImageIcon {
  @extend .NoRepeat;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.ArtOnU {
  @extend .NoRepeat;
  width: 150px;
  height: 72px;
  background-image: url($ArtOnU);
}
.ArtFans {
  @extend .NoRepeat;
  width: 91px;
  height: 26px;
  background-image: url($ArtFans);
}
.AboutUs {
  @extend .NoRepeat;
  width: 134px;
  height: 35px;
  background-image: url($AboutUs);
}
.Ups {
  @extend .NoRepeat;
  width: 274px;
  height: 170px;
  background-image: url($Ups);
  padding: 17.7px 26.5px;
  display: inline-block;
}
.MarginImagesMain {
  margin-bottom: 20px;
  margin-bottom: 16px; /* -4px */
  display: inline-block;
  height: 35px;
}
.WeCreateToBeYourself {
  @extend .NoRepeat;
  @extend .MarginImagesMain;
  width: 388px;
  background-image: url($WeCreateToBeYourself);
}
.NewArrivals {
  @extend .NoRepeat;
  @extend .MarginImagesMain;
  width: 244px;
  background-image: url($NewArrivals);
}
