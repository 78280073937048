/* latin Arimo*/
/**
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
*/
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  /*src: url(https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-ERCrDfQY.woff2)
    format("woff2");*/
  src: url("../assets/fonts/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-ERCrDfQY.woff2")
      format("woff2"),
    url("../assets/fonts/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-ERCrDfQY.ttf")
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  /*src: url(https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-2RCrDfQY.woff2)
    format("woff2");*/
  src: url("../assets/fonts/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-2RCrDfQY.woff2")
      format("woff2"),
    url("../assets/fonts/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-2RCrDfQY.ttf")
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  /*src: url(https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9aQyrDfQY.woff2)
    format("woff2");*/
  src: url("../assets/fonts/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9aQyrDfQY.woff2")
      format("woff2"),
    url("../assets/fonts/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9aQyrDfQY.ttf")
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Arimo";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  /*src: url(https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9jQyrDfQY.woff2)
    format("woff2");*/
  src: url("../assets/fonts/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9jQyrDfQY.woff2")
      format("woff2"),
    url("../assets/fonts/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9jQyrDfQY.ttf")
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  /*src: url(https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxcABrB.woff2)
    format("woff2");*/
  src: url("../assets/fonts/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxcABrB.woff2")
      format("woff2"),
    url("../assets/fonts/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxcABrB.ttf")
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  /*src: url(https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk338xcABrB.woff2)
    format("woff2");*/
  src: url("../assets/fonts/P5sfzZCDf9_T_3cV7NCUECyoxNk338xcABrB.woff2")
      format("woff2"),
    url("../assets/fonts/P5sfzZCDf9_T_3cV7NCUECyoxNk338xcABrB.ttf")
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  /*src: url(https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk3M8tcABrB.woff2)
    format("woff2");*/
  src: url("../assets/fonts/P5sfzZCDf9_T_3cV7NCUECyoxNk3M8tcABrB.woff2")
      format("woff2"),
    url("../assets/fonts/P5sfzZCDf9_T_3cV7NCUECyoxNk3M8tcABrB.ttf")
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  /*src: url(https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk3CstcABrB.woff2)
    format("woff2");*/
  src: url("../assets/fonts/P5sfzZCDf9_T_3cV7NCUECyoxNk3CstcABrB.woff2")
      format("woff2"),
    url("../assets/fonts/P5sfzZCDf9_T_3cV7NCUECyoxNk3CstcABrB.ttf")
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
