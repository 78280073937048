/*
 *
 ? Font Size Var
 *
 *
 */
$MainFontSize: 16px;
$FontNormal: 1rem; /* !16px */
$FontMedium: 0.8125rem; /* !13px */
$FontSmall: 0.625rem; /* !10px */
$FontXS: 0.5rem; /* !8px */
$FontXXS: 0.375rem; /* !6px */
$h1: 3.8125rem; /* !61px */
$h2: 3.0625rem; /* !49px */
$h3: 2.4375rem; /* !39px */
$h4: 1.9375rem; /* !31px */
$h5: 1.5625rem; /* !25px */
$h6: 1.25rem; /* !20px */
/*
 *
 *
 *
 *paragraph-spacing -> margin-bottom: 0;
 *
 *
 */
html,
body {
  font-family: "Arimo", "Arial", sans-serif;
  font-weight: 400; /* Regular | Normal */
  line-height: 1.2; /* 120% */
  letter-spacing: 0;
  font-size: $MainFontSize;
}
.FontNormal {
  font-size: $FontNormal;
}
.FontMedium {
  font-size: $FontMedium;
}
.FontSmall {
  font-size: $FontSmall;
}
.FontXS {
  font-size: $FontXS;
}
.FontXXS {
  font-size: $FontXXS;
}
.IconSize {
  /*line-height: 0.65;*/
  line-height: 0.8;
}
.IconLarge {
  @extend .IconSize;
  @include IconTextSize(81px);
}
.IconMedium {
  @extend .IconSize;
  @include IconTextSize(71px);
}
/**
 *
 * ! text size's 1
 *
*/
.h1,
h1 {
  font-size: $h1; /* !61px */
}
/**
 *
 * ! text size's 2
 *
*/
.h2,
h2 {
  font-size: $h2; /* !49px */
}
/**
 *
 * ! text size's 3
 *
*/
.h3,
h3 {
  font-size: $h3; /* !39px */
}
/**
 *
 * ! text size's 4
 *
*/
.h4,
h4 {
  font-size: $h4; /* !31px */
}
/**
 *
 * ! text size's 5
 *
*/
.h5,
h5 {
  font-size: $h5; /* !25px */
}
/**
 *
 * ! text size's 6
 *
*/
.h6,
h6 {
  font-size: $h6; /* !20px */
}
