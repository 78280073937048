html {
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  overscroll-behavior: none;
  overflow-x: hidden;
  cursor: default;
}
picture,
main {
  display: block;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin: 0;
  padding: 0;
  border: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
}
::placeholder,
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  line-height: inherit;
}
button,
[type="button"],
[type="reset"],
[type="submit"],
select,
textarea,
button,
input {
  overflow: visible;
  text-transform: none;
  font-size: inherit;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
  background-color: inherit;
  color: inherit;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
[type="search"] {
  appearance: textfield;
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[hidden],
template,
figcaption {
  display: none;
}
figure,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
em {
  font-style: normal;
}
p {
  margin: 0;
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
/**
 *
 *
 * anchor elements
 *
 *
*/
a:hover,
a:focus,
a:visited,
a:active,
a {
  color: inherit;
}
