$BlackGradient: linear-gradient(
  345deg,
  $primary-black 0%,
  $primary-black-opacity50 86%
);
/*$ProductGradient: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0) 83%,
  rgba(0, 0, 0, 0.5) 83%
);*/
$ProductGradient: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0) 86%,
  $primary-black-opacity50 100%
);
$GradientDark: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0) 50%,
  $primary-black-opacity50 100%
);

$CardGradient: linear-gradient(345deg, $third-black 0%, #6c6c6c 100%);
$ContactoBoxShadow: 0px 11px 25.8px 0px rgba(0, 0, 0, 0.1);
$Sticky: -8px 3px 10.9px 0 rgba(0, 0, 0, 0.2);
