@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.woff2") format("woff2");
  src: url("../assets/fonts/icomoon.eot") format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf") format("truetype"),
    url("../assets/fonts/icomoon.woff") format("woff"),
    url("../assets/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.Breadcrumb li::before,
[class^="icon-"]::before,
[class*=" icon-"]::before {
  font-family: "icomoon" !important;
  /*speak: never;*/
  font-style: normal;
  font-weight: inherit;
  font-variant: inherit;
  text-transform: none;
  vertical-align: bottom;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-GoogleBlue::before {
  content: "\e926";
  color: #2196f3;
}
.icon-GoogleGreen::before {
  content: "\e927";
  color: #00b060;
}
.icon-GoogleRed::before {
  content: "\e928";
  color: #f44336;
}
.icon-GoogleYellow::before {
  content: "\e929";
  color: #ffc107;
}
.icon-CrownSimple:before {
  content: "\e908";
}
.icon-CursorClick:before {
  content: "\e92a";
}
.icon-ArrowRight::before {
  content: "\e900";
}
.icon-Bell::before {
  content: "\e901";
}
.icon-Burguer::before {
  content: "\e902";
}
.icon-CaretLeft::before {
  content: "\e903";
}
.Breadcrumb li::before,
.icon-CaretRight::before {
  content: "\e904";
}
.icon-Check::before {
  content: "\e905";
}
.icon-ChevronDown::before {
  content: "\e906";
}
.icon-Close::before {
  content: "\e907";
}
.icon-Closed::before {
  content: "\e909";
}
.icon-Closes::before {
  content: "\e90a";
}
.icon-Edit::before {
  content: "\e90b";
}
.icon-EyeClosed::before {
  content: "\e90c";
}
.icon-EyeOpen::before {
  content: "\e90d";
}
.icon-Facebook::before {
  content: "\e90e";
}
.icon-File::before {
  content: "\e90f";
}
.icon-HeartFill::before {
  content: "\e910";
}
.icon-Hearty::before {
  content: "\e911";
}
.icon-Horizontal::before {
  content: "\e912";
}
.icon-HouseLine::before {
  content: "\e913";
}
.icon-IdentificationCard::before {
  content: "\e914";
}
.icon-Instagram::before {
  content: "\e915";
}
.icon-LinkBreak::before {
  content: "\e916";
}
.icon-Magnifying::before {
  content: "\e917";
}
.icon-Mail::before {
  content: "\e918";
}
.icon-MailOpen::before {
  content: "\e919";
}
.icon-Package::before {
  content: "\e91a";
}
.icon-Search::before {
  content: "\e91b";
}
.icon-Select::before {
  content: "\e91c";
}
.icon-Share::before {
  content: "\e91d";
}
.icon-Shirt::before {
  content: "\e91e";
}
.icon-Tiktok::before {
  content: "\e91f";
}
.icon-Trash::before {
  content: "\e920";
}
.icon-Trolley::before {
  content: "\e921";
}
.icon-User::before {
  content: "\e922";
}
.icon-Vertical::before {
  content: "\e923";
}
.icon-Wallet::before {
  content: "\e924";
}
.icon-X::before {
  content: "\e925";
}
