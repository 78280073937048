/*tablet device's 768*/
@media (max-width: 800px) {
  .HeaderMenus {
    flex-direction: column !important;
    gap: 10px !important;

    .FormData {
      order: 2;
    }
    .Row {
      order: 1;
      gap: initial !important;
      justify-content: space-between;
    }
  }
  footer {
    padding: 50px 15px;
  }
  .FooterFirst {
    flex-direction: column;
    gap: 40px;

    > div {
      flex: none;
    }
    .ArtOnU {
      width: 267px;
    }
  }
}
/***mobile phone´s 480*/
@media (max-width: 520px) {
  .HeaderFirst {
    gap: 10px;
  }
  .WeCreateToBeYourself {
    width: 271px;
  }
  .NewArrivals {
    width: 160px;
    background-size: contain;
    display: block;
    margin: 0 auto;
  }
  .AboutBox .AboutUs {
    width: 91px;
  }
  .FooterSocial {
    gap: 30px;
  }
  .FooterFirst {
    margin-bottom: 40px;
  }
  .ContentBox {
    padding: 0;
  }
  body {
    font-size: 13px;
  }
  .MainBox {
    padding: 5px;
    > div {
      padding: 15px;
      border-width: 4px;
    }
    .MainButton {
      bottom: 5px;
    }
    .ButtonLight2 {
      display: block;
      margin: 0 5px;
      text-align: left;

      i {
        float: right;
      }
    }
  }
  .MainContentItems {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  .MainImageBox {
    aspect-ratio: 1 / 1.2;

    .MainButton {
      bottom: 20px;
    }
  }
  .AboutBox {
    padding: 5px;
    gap: 10px;
  }
  .ArtFansBox {
    .ButtonContent {
      display: block;
    }
  }
  .ButtonLight2 {
    padding: 5px;
    border-width: 1px;
  }
  /**
   *
   *
   * ? Home Category
   *
   *
  */
  .CategoryTitle {
    padding: 10px 15px 0 15px;
  }
  .CategoryBox {
    padding: 15px;
    gap: 15px;

    .ImageButtonBG {
      padding-bottom: 70%;
    }
    .ButtonContent {
      border-width: 3px;
      font-size: $h5;
    }
  }
  /**
   *
   *
   * ? Home Colecciones
   *
   *
  */
  .ColeccionMenu {
    position: relative;
    display: block;
    padding: 0 15px 15px 15px;

    ul li {
      margin-bottom: 4px;
    }
    div:last-child {
      position: absolute;
      top: -22px;
      right: 15px;
    }
  }
  .ColeccionItems {
    gap: 15px;
    padding: 1px 15px 5px 15px;

    &.ColeccionVertical,
    &.ColeccionHorizontal {
      grid-template-columns: initial;
    }
    &.ColeccionVertical {
      justify-content: center;
      grid-template-columns: initial;

      article {
        width: 220px;
        max-width: 220px;
      }
    }
    &.ColeccionHorizontal {
      article {
        gap: 15px;

        > div {
          aspect-ratio: 1 / 1.1;
          flex: 1 !important;
        }
      }
    }

    .ItemDesc {
      p,
      ul {
        font-size: $FontXS;
      }
      h6 {
        font-weight: normal;
        font-size: $FontSmall;
      }
      span {
        font-weight: bold;
      }
    }
  }
  .CategoryTitle {
    h3 {
      font-size: $h5;
    }
  }
  .ColeccionPagination {
    padding: 20px 0;
    font-size: 20px;

    .Selected {
      &::after {
        top: 0;
      }
    }
  }
  /**
   *
   *
   * ? Home Resultados Zero
   *
   *
  */
  .ResultadosZero {
    padding: 40px 15px 55px 15px;

    b {
      font-size: $FontNormal;
    }
    p {
      font-size: $FontSmall;
    }
  }
  .Ups {
    width: 124px;
    height: 76px;
  }
  .InteresBox {
    padding: 10px 0 10px 15px;

    b {
      font-size: $h6;
    }
    .InteresBoxImgs {
      margin-top: 5px;
      /*gap: 10px;*/

      div {
        width: calc((100% - 20px) / 3);
        margin-right: 10px;
      }
    }
  }
  /**
   *
   *
   * ? Detalles Producto
   *
   *
  */
  .DetallesProducto {
    padding: 10px 15px;
    display: block;

    > div {
      flex-direction: column;
      gap: 15px;
    }
  }
  .Thumbnail {
    margin-top: 15px;
    gap: 10px;

    ul {
      gap: 10px;
    }
  }
  .ProductoDescripcion {
    > div {
      gap: 15px;
    }
    h4 {
      font-size: $h6;
    }
    h6 {
      font-size: $FontNormal;
    }
  }
  .ProductoImagenes > div:first-child {
    min-width: initial;
  }
  .ProductoAgotado {
    padding: 0 40px;

    > div {
      width: initial;
    }
    h4 {
      font-size: $h5;
    }
  }
  /**
   *
   *
   * ? Home AgregadoCarrito
   *
   *
  */
  .AgregadoCarrito {
    padding: 0;

    em {
      margin: 40px 0;
    }
    .icon-Check {
      font-size: 75px;
    }
    h3 {
      font-size: $h5;
    }
    > div {
      display: block;
      width: initial;
      padding: 20px 15px 15px 15px;

      > p {
        margin-top: 30px;
      }
    }
  }
  .AgregadoItem {
    b + span {
      display: block;
    }
  }
  /**
   *
   *
   * ? Home Carrito
   *
   *
  */
  .Carrito {
    display: block;
    padding: 0;
  }
  .CarritoList {
    h3 {
      font-size: $h5;
    }
  }
  .CarritoResume {
    h6 {
      font-size: $FontNormal;
    }
  }
  /**
   *
   *
   * ? Home Instagram
   *
   *
  */
  .InstagramBox {
    display: block;
    white-space: nowrap;
    margin: 0 auto ;
max-width: 80%;
    // .slick-active{
    //   .InstagramItem{
    //     opacity: 1;
    //     min-width: 200px;
    //   }
    // }
    .slick-slider {
      margin-left: -12%;
      margin-right: -12%;
    }

      .slick-list {
          padding-top: 10%!important;
          padding-bottom: 16%!important;
          padding-left: 15%!important;
          padding-right: 15%!important;
      }
      .slick-track {
        max-width: 100%!important;
        transform: translate3d(0, 0, 0)!important;
        perspective: 100px;
    }
    .slick-slide {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0;
        width: 100%!important;
        transform: translate3d(0, 0, 0);
        transition: transform 1s, opacity 1s;
    }
    .slick-current {
        opacity: 1;
        position: relative;
        display: block;
        transform: translate3d(0, 0, 20px);
        z-index: 2;
    }
    .slick-snext,
    .slick-sprev {
        display: block;
    }

    .slick-snext {
        opacity: 1;
        transform: translate3d(30%, 0, 0px);
        z-index: 1;
    }

    .slick-sprev {
        opacity: 1;
        transform: translate3d(-30%, 0, 0px);
    }
  }
  .InstagramItem {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.10);
    img{
        width: 100%;
        height: 190px;
        object-fit: cover;
    }
    // margin-top: 20px;

    div {
      padding-bottom: 100%;
    }
    p {
      white-space: wrap;
      gap: 7px;
      display: none;

      i {
        font-size: 12px;
      }
    }
  }
  // .InstagramItem:nth-child(2) {
  //   opacity: 1;

  //   div {
  //     position: relative;
  //     z-index: 1;
  //     transform: scale(1.37);
  //   }
  //   p {
  //     position: relative;
  //     display: flex;
  //     top: 10px;
  //     margin-left: -45%;
  //     width: 200%;
  //   }
  // }
  // .ArtFansPerfil,
  // .InstagramItem:nth-child(3) ~ div {
  //   display: none;
  // }
  /**
   *
   *
   * ? Checkout
   *
   *
  */
  .CheckoutBox {
    padding: 0;

    article {
      padding: 15px;
      margin: 0;
    }
    .TarjetasGuardadas,
    .DireccionesGuardadas,
    .icon-Wallet,
    .icon-Package,
    .icon-HouseLine {
      margin: 15px 0;
    }
    .ButtonDarkMedium {
      margin: 40px 0 20px 0;
    }
  }
  .CompraExitosa {
    .StepCheckout ~ .Row {
      flex-direction: column;
    }
    .ButtonLightMedium {
      order: 2;
      margin: 0 0 15px 0;
    }
    .ButtonDarkMedium {
      order: 1;
      margin: 0;
    }
  }
  /**
   *
   *
   * ? Perfil
   *
   *
  */
  .PerfilSection {
    h3 {
      display: block;
    }
  }
  .MisPedidos {
    grid-template-columns: repeat(1, 1fr);
    padding: 30px 15px;
    gap: 20px;
  }
  .BlackIconPerfil {
    margin: 15px 15px 0 15px;
  }
  .PerfilInfo {
    margin: 15px 15px 0 15px;
    padding: 15px 10px;
    background: #f5f5f5;

    + hr {
      margin: 15px;
    }
    .PerfilBox {
      justify-content: initial;
      flex-direction: column;
    }
    .ProfileCard {
      margin-top: 20px;
    }
  }
  .ButtonContent {
    display: block;
    border-width: 3px !important;
  }
  .Sticky {
    .ButtonContent {
      display: table;
    }
  }
  .PerfilData {
    flex-direction: column;
    margin: 15px 0 40px 0;
    padding: 0 15px;
    gap: 15px;
  }
  /**
   *
   *
   * ? Statics
   *
   *
  */
  .Error404 {
    margin: 40px 15px 150px 15px;

    .icon-LinkBreak {
      font-size: 45px;
    }
    em {
      padding: 15px;
      font-size: 70px;
    }
    h5 {
      font-size: $FontNormal;
    }
  }
  .Contacto {
    h5 {
      font-size: $FontNormal;
    }
    article:first-child {
      padding-bottom: 55%;
      background-position: 25% 96%;
      background-size: 125%;
    }
  }
  article.ContactoBox {
    padding: 0 15px 0 15px;
  }
  article.ContactoBox > div {
    top: -44px;
    padding: 15px !important;

    &:last-child {
      margin-top: 20px;
    }
  }
  .ArticleStatic {
    article:first-child {
      padding-bottom: 40%;
    }
    .ImageButtonBG .ButtonContent {
      max-width: 250px;
    }
  }
  .ArticleStaticContent {
    padding: 10px 0 0 0;
  }
  .Contactanos {
    padding: 20px 15px;
  }
  .SobreNosotros {
    .ArtOnU {
      width: 127px;
      height: 60px;
    }
    article:first-child {
      padding-bottom: 50%;
    }
    .Row {
      flex-direction: column;
      padding: 10px 0 20px 0;
    }
    .InstagramAbout {
      gap: 0;

      .Row {
        flex-direction: row;
      }
      .InstagramBox {
        padding: 0;
      }
    }
    .AboutImage {
      height: 189px;
      background-position: 0 29%;
    }
    .WeCreateToBeYourself {
      width: 271px;
      height: 24px;
    }
    .MainBox {
      padding-bottom: 20px;
    }
    article:last-child {
      padding: 0;
    }
  }
  /**
   *
   *
   * ? Others
   *
   *
  */
  .Sticky {
    display: table;

    .ButtonContent {
      padding: 4px 20px;
    }
  }
  .AboutBox {
    flex-direction: column;
  }
  .GrayBG {
    background: $primary-black;
  }
  header {
    padding: 10px 5px;
  }
  .Navigation {
    padding: 5px;
    cursor: grab;

    li {
      padding-right: 10px;
    }
  }
  .FooterFirst {
    align-items: initial;
  }
  .FooterLinks > div {
    display: block;
    align-items: initial;
    gap: 0;
  }
}
