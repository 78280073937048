*,
::after,
::before {
  box-sizing: border-box;
}
::selection {
  color: $primary-white;
  background: $primary-black;
  text-shadow: none;
}
/**
 *
 *
 * ? font-size: calc(10px + 2vmin);
 *
 *
*/
.Hide {
  display: none !important;
}
.TextLeft {
  text-align: left;
}
.TextCenter {
  text-align: center;
}
.TextRight {
  text-align: right;
}
/**
 *
 *
 * ? Otros
 *
 *
*/
#root {
  overflow-x: hidden !important;
}
.IconGoogle {
  position: relative;
  padding-right: 29px;
  i {
    position: absolute;
  }
}
.TextColorSec {
  p {
    display: inline;
    padding-right: 5px;
  }
}
p + p {
  margin-top: 20px;
}
/**
 *
 *
 * ? Background
 *
 *
*/
.DarkBG {
  background: $primary-black;
}
.GrayBG {
  background: $secondary-black;
}
/**
 *
 *
 * ? Sizes
 *
 *
*/
.AllHeight {
  min-height: 100vh;
}
/**
 *
 *
 * ? Center
 *
 *
*/
.CenterElement {
  display: flex;
  align-items: center;
  justify-content: center;
}
/**
 *
 *
 * ? Boxs
 *
 *
*/
.ContentBox {
  padding: 60px 0;
  @extend .GrayBG;
  @extend .AllHeight;
}
.ContentChildCenter {
  @extend .CenterElement;
}
.Column {
  display: flex;
  flex-direction: column;
}
.Row {
  display: flex;
  flex-direction: row;
}
/**
 *
 *
 * ? Frames
 *
 *
*/
.FrameBorder {
  border: 4px solid $primary-white;
  padding: 7px;
}
/**
 *
 *
 * ? ComingSoon Page
 *
 *
*/
.ComingSoon {
  @extend .CenterElement;
  @extend .AllHeight;
  position: relative;
  background-image: url($ComingSoonImage);
  background-position: 0 19%;
  background-size: cover;
  background-repeat: no-repeat;

  .FrameComingSoon {
    @extend .Column;
    position: relative;
    max-width: 388px;
    gap: 40px;
    margin: 40px 0;
    width: 65vw;
  }
}
.BlackGradient {
  position: absolute;
  width: 100vw;
  min-height: 100%;
  background: $BlackGradient;
  opacity: 0.9;
}
/**
 *
 *
 * ? Auth IniciarSesion Page
 *
 *
*/
.IniciarSesion {
  @extend .DarkBG;
  @extend .Column;
  width: 450px;
  gap: 25px;
  padding: 80px 20px;

  .GroupButton {
    @extend .Column;
    gap: 15px;
  }
  .DataBox {
    @extend .Column;
    gap: 25px;
  }
  .Row {
    gap: 10px;

    .FormData {
      flex: 1;
      min-width: 0; /* fix width outside box */
      box-sizing: border-box;
    }
  }
}
/**
 *
 *
 * ? Forms
 *
 *
*/
::placeholder {
  @extend .FontMedium;
  color: $secondary-gray;
}
.FormData {
  @extend .TextLeft;

  em {
    @extend .FontXS;
    display: block;
    margin-bottom: 4px;
  }
  .RequiredData {
    &::after {
      color: $red;
      content: "*";
      padding-left: 2px;
      vertical-align: super;
    }
  }
  .InfoData {
    display: none;
  }

  &.InvalidInput {
    .FormInput {
      border-color: $red;
    }
    .InfoData {
      margin: 6px 0 0 0;
      color: $red;
      display: block;
    }
  }
}
.FormInput {
  display: flex;
  flex-direction: row;
  border: 2px solid $secondary-gray;
  background: $primary-white-20;
  padding: 12px 10px;
  transition: border-color 0.2s ease-in-out;

  textarea,
  input,
  select {
    flex: 1;
    min-width: 0;
  }
  &:focus-within {
    border-color: transparent;
    border-bottom-color: $primary-white;
  }
  textarea{
    height: 6.25rem;
  }
}
.FormInputLight {
  @extend .FormInput;
  background: $primary-white;
  border-width: 1px;

  &:focus-within {
    border-color: $primary-black;
  }
}
.FormInputMedium {
  @extend .FormInput;
  padding: 7px 10px;

  .InputIcon::before {
    @extend .FontNormal;
    top: -10px;
  }
}
.InputIcon {
  @extend .TextCenter;
  @extend .h5;
  width: 22px;
  height: 0;

  &::before {
    position: relative;
    top: -4px;
  }
}
/**
 *
 *
 * * Home
 * ? Home Header
 *
 *
*/

.pt-tophead{
  padding-top: 10.5rem !important;
}
@media screen and (max-width:768px) {
  .pt-tophead{
    padding-top: 9.5rem !important;
  }
}
footer,
header {
  text-align: center;
  background: $primary-black;
  color: $primary-white;

  a {
    text-decoration: none;
  }
}
header {
  padding: 20px 40px 10px 40px;
  position: fixed;
  width: 100%;
  top:0;
  z-index: 10;
  &.Column {
    gap: 10px;
  }
  .HeaderSelect {
    @extend .Row;
    justify-content: end;
    gap: 10px;

    option {
      color: $primary-black;
      text-align: right;
    }
    i {
      padding-left: 6px;
    }
  }
}
.HeaderFirst {
  @extend .Row;
  align-items: center;
  gap: 50px;

  .HeaderMenus {
    @extend .Row;
    gap: 50px;
    flex: 1;

    .FormData {
      flex: 1;
    }
    .Row {
      @extend .h6;
      gap: 25px;
      align-items: center;
    }
  }
}
/**
 *
 *
 * ? Bubble
 *
 *
*/
.Bubbles {
  > div {
    position: relative;
  }
  .Bubble {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 13px;
    background: $red;
    font-size: $FontSmall;
    top: 0;
    right: -6px;
  }
}
/**
 *
 *
 * ? Navigation
 *
 *
*/
.Navigation {
  @extend .TextCenter;
  position: fixed;
  padding: 5px 40px;
  background: $secondary-black;
  color: $primary-white;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  top: 8.1875rem;
  width: 100%;
  z-index: 10;
  ul {
    position: relative;
  }
  li {
    display: inline-block;
    padding-right: 25px;
  }
  a {
    display: block;
    text-decoration: none;
    padding: 5px 0 5px 5px;
  }
}
@media screen and (max-width:768px) {
  .Navigation{
      top: 8rem;
  }
}
@media screen and (max-width:520px) {
  .Navigation{
      top: 7.3rem;
  }
}
/**
 *
 *
 * ? Breadcrumb
 *
 *
*/
.CategoryTitle {
  padding: 20px 40px 0 40px;
}
.Breadcrumb {
  margin-bottom: 5px;
  color: $gray;
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  li {
    display: flex;
    align-items: center;

    &:first-child::before {
      padding-left: 0;
      content: "";
    }
    &::before {
      padding: 0 5px;
      font-size: 13px;
      vertical-align: middle;
    }
  }
}
/**
 *
 *
 * ? Home Main
 *
 *
*/
.MainButton {
  text-align: center;
  width: 100%;
  position: absolute;
}
.MainImage {
  position: relative;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
.MainImageBox {
  /* 1920x800 */
  @extend .MainImage;
  aspect-ratio: 24 / 10;
  background-image: url($Person6);
  background-position: 0 50%;

  .MainButton {
    bottom: 100px;
  }
  .heroImage{
    img{
      width: 100%;
    }
  }
  .link{
    text-decoration: none;
  } 
}
.MainBox {
  padding: 40px;
  text-align: center;

  > div {
    padding: 20px;
    border: 6px solid $secondary-black;
  }
}
.Sticky {
  display: none;
  position: fixed;
  transform: rotate(270deg);
  transform-origin: bottom right;
  z-index: 99;
  white-space: nowrap;
  top: 50%;
  margin-top: -126px;
  right: 0;

  > div {
    /*position: absolute;
    bottom: 0;
    right: -35px;
    z-index: 99;*/
    box-shadow: $Sticky;
  }
}
.MainContentItems {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  .MainButton {
    bottom: 40px;
  }
}
.MCtemsButtons {
  > div {
    @extend .MainImage;
    aspect-ratio: 435 / 560;
    background-position: center;
  }
}
/**
 *
 *
 * ? Home Instagram box
 *
 *
*/
.InstagramBox {
  // @extend .Row;
  // gap: 20px;

  // .InstagramItem {
  //   user-select: none;
  //   flex: 1;
  // }
  
}

.InstagramBox {
  width: 100%;
  // height: 200px; 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px; 
}

.InstagramItem {
  width: 95%;
  // max-width: 250px; 
  margin: auto;
}

.slick-slide {
  display: flex;
  justify-content: center;
}
.IGBoxMini {
  display: block;
  white-space: nowrap;

  .InstagramItem {
    width: 111px;
    opacity: 0.5;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;

    p {
      white-space: wrap;
      gap: 7px;
      display: none;

      i {
        font-size: 12px;
      }
    }
  }
  .InstagramItem:nth-child(2) {
    opacity: 1;

    div {
      position: relative;
      z-index: 1;
      transform: scale(1.37);
    }
    p {
      display: flex;
      margin-top: 40px;
      margin-left: -40%;
      width: 180%;
    }
  }
  .InstagramItem:nth-child(3) ~ div {
    display: none;
  }
}
.InstagramItem {
  div {
    width: 100%;
    padding-bottom: 118%;
    background-size: cover;
    background-position: center;
    /*aspect-ratio: 283 / 334;*/
  }
  i {
    font-size: 23px;
  }
  p {
    @extend .FontMedium;
    @extend .Row;
    margin-top: 20px;
    gap: 10px;
  }
}
/**
 *
 *
 * ? Home Category
 *
 *
*/
.ImageButton,
.CategoryBox {
  .ImageButtonBG {
    @extend .MainImage;
    position: relative;

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary-black-opacity60;
    }
    .ButtonContent {
      border: 4px solid $primary-white;
      background-color: transparent;
    }
  }
}
.Contacto {
  .ImageButtonBG {
    > div {
      background: transparent;
    }
  }
}
.CategoryBox {
  @extend .Column;
  gap: 20px;
  padding: 20px 40px;

  article {
    padding-bottom: 26.5%;
    background-position: center;
  }
}
.ImageButtonBG,
.CategoryBox {
  .ButtonContent {
    padding: 20px;
    font-weight: bold;
    font-size: $h3;
  }
}
/**
 *
 *
 * ? Home Colecciones
 *
 *
*/
.ColeccionMenu {
  @extend .Row;
  padding: 0 40px 40px 40px;
  justify-content: space-between;
  align-items: end;

  ul {
    margin-top: 5px;
    cursor: pointer;
  }
  ul li {
    display: inline-block;
    margin-right: 4px;
    border: 1px solid $primary-black;
    padding: 2px 5px;
  }
  .Selected {
    background: $primary-black;
    color: $primary-white;
  }
  span {
    padding: 0 5px;
    cursor: pointer;

    &:first-child {
      padding-right: 5px;
    }
    &::before {
      padding-right: 2px;
    }
  }
}
.ColeccionItems {
  display: grid;
  gap: 20px;
  padding: 10px 40px;

  &.ColeccionHorizontal {
    grid-template-columns: repeat(2, 1fr);

    em {
      display: none;
    }
    article {
      @extend .Row;
      gap: 20px;
      align-items: center;

      > div {
        /*aspect-ratio: 1 / 0.5;
        flex: 0;*/

        &:first-child {
          /*flex: 1;
          padding-bottom: 90%;*/
          height: 100%;
          width: 150%;
          max-width: 270px;
        }
        &:last-child {
          /*flex: 2;*/
          display: flex;
          align-items: center;
        }
      }
    }
  }
  &.ColeccionVertical {
    grid-template-columns: repeat(4, 1fr);

    article {
      @extend .Column;
      gap: 5px;

      > div:first-child {
        /*aspect-ratio: 1 / 0.9;*/
        position: relative;
        padding-bottom: 90%;
        height: 0;
      }
    }
    p {
      display: none;
    }
  }

  .socials-list {
    display: flex;
    flex-direction: column;
    gap: .3125rem;
    position: absolute;
    // top: 50px;
    right: .3125rem;
    bottom: 2.0625rem;
    // background: #fff;
    // border: 1px solid #ccc;
    // padding: 10px;
    // border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 1;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .socials-list a {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: .3125rem;
  }
  
  .socials-list a.facebook {
    background: url('../../src/assets/icons/icon-facebook.svg') no-repeat center;
    background-size: contain;
  }
  
  .socials-list a.twitter {
    background: url('../../src/assets/icons/icon-x.svg') no-repeat center;
    background-size: contain;
  }
  
  .socials-list a.link {
    background: url('../../src/assets/icons/icon-link.svg') no-repeat center;
    background-size: contain;
  }
  
  .socials-list.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .share-menu.show {
    opacity: 1;
    transform: scale(1);
  }
  
  .share-menu a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .share-menu i {
    font-size: 20px;
  }
  .icon-Share{
    bottom: .3125rem;
  }

  .notification-banner {
    position: absolute;
    right: 2.1875rem;
    bottom: 3rem;
    background-color: #fff;
    color: #76BE00;
    padding: .125rem .25rem;
    border-radius: .125rem;
    font-size: .875rem;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateX(-50px);
    animation: slide-in 0.3s ease forwards, fade-out 0.3s ease 2.7s forwards;
    display: flex;
    align-items: center;
    gap: .25rem;
    &::before{
      content: '';
      clear: both;
      display: block;
      background:url('../../src/assets/icons/check-link.svg') no-repeat center;
      width: 1rem;
      height: 1.0625rem;
    }
  }
  
  /* Animación para mostrar la bandera */
  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Animación para ocultar la bandera */
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
.InteresBoxImgs,
.ColeccionItems,
.MainContentItems {
  figure,
  picture,
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  .icon-Hearty {
    top: 5px;
  }
}

.ColeccionItems {
  figure,
  picture,
  img {
    position: absolute;
  }
}
.ItemDesc {
  ul li {
    display: inline-block;
    color: $secondary-gray;

    &:first-child + li::before {
      content: "|";
      padding: 0 4px;
    }
  }
  span {
    display: block;
  }
  h6,
  span,
  p {
    margin-top: 5px;
  }
  h6 {
    font-weight: bold;
  }
  p {
    font-size: $FontMedium;
    color: $gray;
  }
  em {
    color: $secondary-gray;
    font-size: $FontSmall;
  }
}
.ProductoImagenes,
.ColeccionItems article {
  > div {
    &:first-child {
      position: relative;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  i {
    @extend .h6;
    position: absolute;
    right: 5px;
    background: $primary-white-60;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;

    &:first-child {
      top: 5px;
    }
    &:last-child {
      bottom: 5px;
    }
  }
}
.ColeccionPagination {
  text-align: center;
  padding: 40px 0;

  ul {
    margin-right: 10px;

    li {
      margin-left: 10px;
    }
  }

  div,
  ul,
  li {
    display: inline-block;
  }

  .Selected {
    position: relative;
    width: 25px;
    color: $primary-white;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background: $secondary-black;
      top: -2px;
      z-index: -1;
    }
  }
}
/**
 *
 *
 * ? Home Resultados Zero
 *
 *
*/
.ResultadosZero {
  text-align: center;
  padding: 40px 0 120px 0;

  i {
    font-size: 44px;
    color: $secondary-gray;
  }
  b {
    font-size: $h5;
  }
  i,
  b {
    display: block;
  }
  p {
    color: $gray;
  }
  div,
  b,
  p {
    margin-top: 15px;
  }
}
.InteresBox {
  position: relative;
  padding: 10px 40px 40px 40px;
  user-select: none;
  overflow: hidden;

  b {
    font-size: $h4;
  }
  .InteresBoxImgs {
    position: relative;
    margin-top: 20px;
    white-space: nowrap;
    /*@extend .Row;
    gap: 20px;*/

    div {
      width: calc((100% - 60px) / 4);
      margin-right: 20px;
      display: inline-block;
      aspect-ratio: 1 / 0.9;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      /*flex: 1;*/
    }
  }
}
/**
 *
 *
 * ? Home Detalles Producto
 *
 *
*/
.DetallesProducto {
  /* top 5px Breadcrumb*/
  padding: 35px 0 80px 0;
  display: flex;

  > div {
    @extend .Row;
    gap: 20px;
    margin: 0 auto;
  }
}
.ProductoImagenes {
  > div:first-child {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 461px;
    aspect-ratio: 1 / 1.15;
    overflow: hidden;

    > div {
      position: relative;
      z-index: 1;
    }
    &::after {
      position: absolute;
      content: "";
      inset: 0;
      background: $ProductGradient;
    }
    > div:last-child {
      position: absolute;
      bottom: 11px;
      right: 7px;
    }
  }

  i {
    position: relative;
    bottom: initial !important;

    &:first-child {
      top: initial;
      right: initial;
    }
    &:last-child {
      right: initial;
      margin-left: 20px;
    }
  }
  /*.Thumbnail,
  > div:last-child*/
}
.Thumbnail,
.SeleccionaTalla > div {
  @extend .Row;
  gap: 13px;
  align-items: center;
  margin-top: 20px;

  div {
    font-size: 26px;
  }
  .Disabled {
    opacity: 0.7;
    cursor: not-allowed;
    border-color: $silver;
    background: $secondary-silver;
    color: $silver;
  }
  ul {
    white-space: nowrap;
    width: 100%;
    display: block;
    overflow: hidden;
    /*@extend .Row;
    gap: 13px;
    flex: 1;*/

    li {
      width: calc((100% - 20px) / 3);
      margin-right: 10px;
      display: inline-block;
      /*flex: 1;*/
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      aspect-ratio: 1 / 1;
      opacity: 0.5;

      &.Selected {
        opacity: 1;
      }
    }
  }
}
.SeleccionaTalla > div ul li {
  width: calc((100% - 35px) / 4);
  padding-left: 0;
  padding-right: 0;
}

.other-payment-methods{
  background-color: #F5F5F5;
  padding: .625rem;
  margin-bottom: 1.875rem;
  .title{
    font-size: .875rem;
    font-weight: 700;
    color: #121212;
    margin-bottom: .5625rem;
  }
  .text{
    display: inline;
    font-size: .8125rem;
    font-weight: 400;
    color: #555;
    a{
      text-decoration: underline;
      color: #555;
      display: inline;
      font-size: .8125rem;
      font-weight: 400;
    }
    .box{
      width: 73%;
    }
  }
}

@media screen and (max-width:768px) {
  .other-payment-methods{
    .text{
      .box{
        width: 100%;
      }
    }
  }
}


.RotateFlag {
  position: relative;
  color: $rose;
  text-align: center;
  transform: rotate(-35.2deg);
  top: -27px; /*50px*/
  left: -87px;
  font-size: $FontMedium;
  font-weight: bold;

  div {
    background: $primary-white;
    padding: 3px 7px;
    max-width: 270px;

    &:first-child {
      margin-bottom: 7px;
    }
    &::before {
      padding-right: 7px;
    }
  }
}
@media screen and (max-width: 470px) {
  .RotateFlag{
    top: -2px;
    font-size: 0.7rem;
  }
}
.ProductoDescripcion {
  // display: flex;
  // align-items: center;
  max-width: 513px;

  .TeAvisaremos {
    color: $green;

    span {
      font-weight: bold;
    }
    i {
      padding-right: 5px;
    }
  }
  .NoDisponible {
    display: table;
    background: $primary-black;
    color: $primary-white;
    padding: 5px 20px;
    margin-bottom: 5px;
    margin-top: .625rem;
    font-size: .625rem;
  }
  .Selected {
    @include ButtonDisplay($secondary-black, $primary-white);
  }
  h6 {
    margin-bottom: 9px;
  }
  .SeleccionaTalla {
    margin-bottom: .625rem;
    h6 {
      margin-bottom: .625rem;
    }
    .text{
      display: inline-block;
      margin-top: 0;
      font-size: .8125rem;
    }
    > div {
     

      div {
        font-size: 20px;
      }
      ul {
        gap: 6px;

        li {
          aspect-ratio: initial;
          opacity: 1;
        }
      }
    }
  }
  .SeleccionaColor {
    margin-bottom: .625rem;
    h6 {
      margin-bottom: 5px;
    }
    > div {
      @extend .Row;
      gap: 6px;
      margin-top: 0;

      div {
        font-size: 20px;
      }
      ul {
        gap: 6px;

        li {
          aspect-ratio: initial;
          opacity: 1;
        }
      }
    }
    .grid{
      display: flex;
      align-items: center;
      gap: .9375rem;
      
    }
    button{
      display: block;
      border: 0;
      border-radius: 3.125rem;
      width: 1.5625rem;
      height: 1.5625rem;
      padding: .125rem;
      cursor: pointer;
      &:hover,
      &.active{
        border: 1px solid black;
      }
      &.color-1{
        background-color: #F76E62;
      }
      &.color-2{
        background-color: #F5DF9D;
      }
      &.color-3{
        background-color: #78C6F2;
      }
      &.color-4{
        background-color: #B0DD9E;
      }
    }
  }
  $counter-width: 2.75rem;
  $button-bg: #000;
  $button-color: #fff;
  $input-bg: white;
  $border-color: #ccc;
  $font-size: 1.25rem;
  .SeleccionaCantidad{
    margin-bottom: 1.25rem;
    h6 {
      margin-bottom: 5px;
    }
    > div {
      @extend .Row;
      gap: 6px;
      margin-top: 0;
      

      .counter {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .3125rem;

        &__btn {
          width: $counter-width;
          height: $counter-width;
          background-color: $button-bg;
          color: $button-color;
          font-size: 1.625rem;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0;

          &:hover {
            opacity: 0.8;
          }

        }

        &__input {
          width: 4.6875rem;
          height: $counter-width;
          text-align: center;
          font-size: $font-size;
          background-color: $input-bg;
          border: 1px solid $border-color;
          box-sizing: border-box;
          pointer-events: none;
        }
      }
    }
  }
  .EncuentraTalla {
    cursor: pointer;
    margin-bottom: 1.25rem;
    a{
      text-decoration: none;
    }
    em {
      text-decoration: underline;
      font-size: 1.25rem;
    }
    i {
      padding-right: 5px;
      &::before {
        font-size: 1.25rem;
      }
    }
  }

  > div {
    @extend .Column;
    gap: 20px;
  }
  .ItemDesc {
    ul {
      margin-top: 5px;
    }
    h6 {
      margin-bottom: 5px;
    }
  }
}


.modal-talla{
  
  h2{
    display: flex;
    align-items: center;
    gap: .4375rem;
    margin-bottom: 1.25rem;
    padding: 0;
    font-size: 2.4375rem;
    i{
      display: block;
      background: url('../../src/assets/icons/shirt_layer.svg') no-repeat center;
      background-size: contain;
      width: 3.75rem;
      height: 3.75rem;
    }
  }
  .text{
    font-size: .8125rem;
    margin-bottom: 1.25rem;
  }

  img{
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .modal-talla{
    h2{
      font-size: 2rem;
    }
  }
}

/**
 *
 *
 * ? Home Producto Agotado
 *
 *
*/
.ModalGracias,
.ProductoAgotado {
  position: fixed;
  z-index: 99;
  background: $primary-black-opacity80;
  inset: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  align-items: center;
  justify-content: center;

  > div {
    position: relative;
    width: 513px;
    background: $primary-white;
    padding: 15px;

    h4 {
      font-weight: bold;
    }
    h4,
    p,
    div {
      margin-top: 20px;
    }
  }
  .icon-Closed {
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 32px;
    cursor: pointer;

    &::after {
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      background: $primary-white;
      left: 3px;
      top: 7px;
      border-radius: 50%;
    }
    &::before {
      position: relative;
      z-index: 1;
    }
  }
}
.CarritoResume,
.ModalGracias,
.ProductoAgotado {
  .FormInput {
    background: $primary-white;
    border-color: $secondary-gray;

    &:focus-within {
      border-bottom-color: $primary-black;
    }
  }
}
.ModalGracias{
  h4{
    text-align: center;
    font-size: 1.9375rem;
    margin-bottom: 1.25rem;
  }
  p{
    text-align: center;
    margin-bottom: 1.25rem;
  }
  .icon-Check {
    width: 100%;
    max-width: 5rem;
    margin: 0 auto;
    &::before{
      display: none;
    }
  }
}
/**
 *
 *
 * ? Home Producto Agregado a Carrito
 *
 *
*/
.AgregadoCarrito {
  // padding: 40px 0;
  // background: $secondary-silver;
  position: fixed;
  z-index: 99;
  background: $primary-black-opacity80;
  inset: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  align-items: center;
  justify-content: center;

  > div {
    position: relative;
    width: 513px;
    background: $primary-white;
    padding: 15px;
  }

  .icon-Closed {
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 32px;
    cursor: pointer;

    &::after {
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      background: $primary-white;
      left: 3px;
      top: 7px;
      border-radius: 50%;
    }
    &::before {
      position: relative;
      z-index: 1;
    }
  }
  
  em {
    display: block;
    text-align: center;
    color: $gray;
    margin: 45px 0;
  }
  .icon-Check {
    font-size: 100px;
    text-align: center;
  }
  h3 {
    margin-top: 10px;
    text-align: center;
  }
  > div {
    display: table;
    width: 627px;
    margin: 0 auto;
    padding: 20px 20px 35px 20px; /*+15 px bottom*/
    background: $primary-white;
  }
  p {
    margin-top: 60px; /*+15 px*/
    color: $gray;
  }
  p,
  .ButtonDarkMedium {
    margin-bottom: 15px;
  }
}
.AgregadoItem {
  margin-top: 45px;
  @extend .Row;
  justify-content: space-between;

  p {
    margin: 0;
    color: $secondary-black;
  }
  em {
    display: block;
    color: $secondary-gray;
    font-size: $FontSmall;
    margin: 0 !important;
    margin-top: 5px !important;
    text-align: left !important;
  }
  .links{
    display: flex;
    align-items: center;
    gap: .625rem;
    margin-top: .3125rem;
    a{
      font-size: .625rem;
      font-weight: 400;
      color: #818181;
      text-decoration: underline;
    }
  }
  > div:first-child {
    @extend .Row;
  }
  > div:last-child {
    text-align: right;
    white-space: nowrap;
    font-size: .8125rem;
    font-weight: 400;
    color: #28292B;
    .ButtonDarkThiny {
      margin: 14px 0;
    }
    div {
      font-weight: 400;

      &::before {
        padding-right: 5px;
      }
    }
  }
  .AgregadoItemImg {
    width: 80px;
    height: 80px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    + div {
      margin-left: 11px;
      max-width: 110px;
    }
  }
}
.MisPedidos {
  .AgregadoItemImg {
    + div {
      max-width: initial;
      margin-bottom: 20px;
    }
  }

}
/**
 *
 *
 * ? Home Carrito
 *
 *
*/
.Carrito {
  padding: 40px 60px;
  @extend .Row;
  gap: 100px;
}
.Carrito,
.PerfilContent {
  max-width: 1440px;
  margin: 0 auto;
}
.CarritoList {
  flex: 0.561;
  padding: 10px 15px 15px 15px;

  > div {
    margin-top: 15px;
  }
}
.CarritoResume {
  flex: 0.439;
  background: $secondary-silver;
  padding: 15px;

  > p {
    color: $gray;
    margin: 20px 0;
  }
  .Row {
    justify-content: space-between;
    margin: 20px 0;
    gap: 5px;

    .FormInput {
      flex: 1;
    }
  }
  div {
    font-weight: 400;
  }
  h6,
  h5 {
    font-weight: bold;
  }
}

.PerfilContent{
  .grid-title{
    padding: 20px 40px 0 40px;
    display: flex;
    align-items: center;
    gap: 1.25rem;
    margin-bottom: 1.25rem;
    .BlackIconPerfil{
      margin: 0;
    }
    span{
      display: block;
      width: .0313rem;
      background-color: #DEDEDE;
      height: 2.4375rem;
    }
    h2{
      margin: 0;
      padding: 0;
      font-size: 1.5625rem;
    }
  }

}
/**
 *
 *
 * ? Home Checkout
 *
 *
*/
.CheckoutBox {
  background: $silver;
  padding: 60px 0;

  h5 {
    font-weight: bold;
  }
  article {
    max-width: 700px;
    background: $primary-white;
    margin: 0 auto;
    padding: 40px;
    padding-bottom: 60px;
  }
  .icon-Wallet,
  .icon-Package,
  .icon-HouseLine {
    font-size: 28px;
    margin: 20px 0;
  }
  .TarjetasGuardadas{
    font-size: 1rem;
    font-weight: 700;
    color: #28292B;
  }
  .TarjetasGuardadas,
  .DireccionesGuardadas {
    margin: 20px 0 0;

    .FontSmall {
      margin: 5px 0 15px 0;
    }
    label {
      margin-bottom: 15px;
    }
  }
  .DireccionesGuardadas{
    .text-description{
      padding: 1.25rem 0;
      font-size: .8125rem;
      font-weight: 400;
      color: #555;
      em{
        font-style: italic;
      }
    }
  }
  .line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      font-size:.625rem;
      color: #555;
      font-weight: 500
    }
    .price{
      font-size: 1rem;
      color: #28292B;
      font-weight: 700;
    }
    a{
      font-size: .5rem;
      color: #555;
      font-weight: 400;
      display: block;
      text-decoration: underline;
    }
  }
  .ItemTarjeta .Selected{
    background: #FFF !important;
    &::before{
      background: #FFF !important;
    }
  }
  .hover{
    display: none;
  }
}
.StepCheckout {
  @extend .Row;
  gap: 5px !important;

  div {
    flex: 1;
    height: 4px;
    background: $third-black;
    opacity: 0.25;
  }
  .Selected {
    opacity: 1;
  }
}
.FormTarjeta,
.FormDireccion {
  // background: $secondary-silver;
  padding: 10px;

  .FontSmall {
    margin: 5px 0 15px 0;
  }
  label {
    display: block;
    margin-bottom: 15px;
  }
  .Row {
    gap: 10px;

    label {
      flex: 1;
    }
  }
}

.FormTarjeta{
  width: 100%;
  max-width: 26rem;
  margin: 0 auto;
  .title{
    font-size: 1.25rem;
    color: rgba(26, 26, 26, 0.90);
    margin-bottom: 1.25rem;
  }
}

.FormDireccion {
  margin-bottom: 40px; /*20px*/
}
.ItemData,
.ItemTarjeta,
.ItemDireccion {
  display: flex;
  align-items: center;
  padding-left: 5px;
  gap: 10px;
  font-size: $FontMedium;
  color: $gray;

  .EditarTarjeta,
  .EditarDireccion {
    text-decoration: underline;
    font-weight: bold;
    color: $primary-black;
  }
  .Selected {
    background: $secondary-silver;
    padding: 5px;
  }
  input:checked {
    position: relative;
    z-index: 1;

    + div {
      position: relative;
      background: $secondary-silver;

      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 32px;
        background: $secondary-silver;
        height: 100%;
        top: 0;
        left: -32px;
      }
    }
  }
  > div {
    padding: 5px 5px 5px 0;
    flex: 1;
  }
  .link{
    font-weight: 400;
    text-decoration: underline;
    font-size: .8125rem;
  }
}
.ResumenPedido {
  margin-top: 20px;
  margin-bottom: 40px; /*20px*/

  .AgregadoItem {
    margin-top: 20px;
  }
}
.ResumenCheckout {
  margin-top: 20px;

  > div {
    @extend .Row;
    justify-content: space-between;
    margin-top: 10px;
  }
}
.ItemTarjeta {
  svg {
    margin-top: 5px;
  }
  > div {
    @extend .Row;
    gap: 10px;
    white-space: nowrap;

    div {
      &:first-child {
        width: 40px;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
}
.CompraExitosa {
  text-align: center;
  article {
    padding: 15px;

    > .icon-Check {
      font-size: 64px;
      margin-top: 40px;
      margin-bottom: 10px;
    }
    h5 {
      margin-bottom: 40px;
    }
    b {
      display: block;
      margin-bottom: 5px;
    }
  }
  .Row {
    margin-top: 60px;
    gap: 15px;
    > div {
      flex: 1;
    }
  }
  .StepCheckout {
    margin: 0;
  }
}
/**
 *
 *
 * ? Home WishList
 *
 *
*/
div.WishList {
  padding: 40px;
}
section.WishList {
  padding-bottom: 40px;
}
.MisPedidos {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  padding: 15px 40px 55px 40px;
  gap: 20px;
  width: 100%;
  max-width: 46.875rem;
  margin: 0 auto;

  .AgregadoItem {
    margin: 0;
    grid-column: 1 / 2;
  }
  .ButtonDarkThiny {
    margin: 7px 0 9px 0 !important;
  }
}

.itemPedido{
  border: 1px solid #DEDEDE;
  background: #FFF;
  .tab{
    display: flex;
    justify-content: space-between;
    padding: .8125rem .9375rem;
    background-color: #F5F5F5;
    color: #818181;
    >div {
      display: flex;
      align-items: center;
      gap: 1.25rem;
    }
    .title{
      font-size: .625rem;
      font-weight: 400;
      line-height: 7px;
    }
    span{
      font-size: .625rem;
      font-weight: 400;
    }
    .id-pedido{
      font-size: .8125rem;
    }
    .total{
      color: black;
      font-size: .8125rem;
    }
    .btn{
      display: flex;
      align-items: center;
      gap: .3125rem;
      border: 0;
      background: none;
      cursor: pointer;
      font-size: .625rem;
      box-shadow: none;
      color: #818181;
      font-weight: 400;
      &::after{
        content: '';
        display: block;
        clear: both;
        background: url('../../src/assets/icons/CaretUp.svg') no-repeat center;
        background-size: contain;
        width: .875rem; 
        height: .875rem;
      }
    }
  }
  .panel{
    padding: .625rem;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 100%;
    .detail{
      display: flex;
      justify-content: space-between;
      // gap: 1.25rem;
      font-size: .625rem;
      color: #818181;
      margin-bottom: 1.25rem;
      >div{
        padding: 0 .75rem;
        border-right: 1px solid #DEDEDE;
        width: 25%;
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          border-right: 0;
        }
      }
      .title{
        font-weight: 700;
        margin-bottom: .3125rem;
      }
      .box-status{
        display: flex;
        align-items: center;
        gap: .3125rem;
        a{
          display: inline-block;
          font-size: .625rem;
          font-weight: 400;
          color: #818181;
          text-decoration: underline;
        }
      }
    }
    .subtitle{
      font-size: .8125rem;
      color: #818181;
      font-weight: 700;
      margin-bottom: 1.25rem;
    }
    .visible{
      max-height: 1000px;
    }
  }
}

@media screen and (max-width: 768px) {
  .itemPedido{
    .tab{
      flex-direction: column;
      justify-content: space-between;
      >div{
        justify-content: space-between;
        margin-bottom: .625rem;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .panel{
      max-height: 100%;
      .detail{
        flex-direction: column;
        > div{
          width: 100%;
          border-right: 0;
          border-bottom: 1px solid #DEDEDE;
          padding: .625rem 0;
          &:last-child{
            border-bottom: 0;
            border-right: 0;
          }
        }
      }
    }
  }
}

/**
 *
 *
 * ? Home Mi Perfil
 *
 *
*/
.PerfilSection {
  h3 {
    display: none;
  }
}
.ArtFansPerfil {
  width: 149px;
  height: 43px;
}
.BlackImage {
  filter: invert(100%);
}
.BlackIconPerfil {
  @extend .BlackImage;
  margin: 20px 40px 0 40px;
}
.PerfilInfo {
  margin: 20px 40px 0 40px;
  padding: 20px 50px 20px 20px;
  background: $secondary-silver;

  .EditarPerfil {
    text-decoration: underline;
  }
  .PerfilBox {
    @extend .Row;
    justify-content: space-between;
  }
  .ButtonContent {
    margin: 12px 0 0 0;
  }
}
.ProfilePic {
  > div {
    &:first-child {
      @extend .Row;
      gap: 12px;
      align-items: center;
    }
  }

  .ProfileImage {
    width: 104px;
    height: 104px;
    border-radius: 104px;
    background-size: cover;
    background-position: center 100%;
    background-repeat: no-repeat;
  }
}
.ProfileCard {
  position: relative;
  margin-top: -61px;

  .Card {
    color: $primary-white;
    background: $CardGradient;
    margin: 0 5px;
    text-align: center;
    border-radius: 8px;
    padding: 15px 0;

    .ArtFans {
      display: inline-block;
    }
    em {
      display: block;
      margin-top: 30px;
    }
    h3 {
      letter-spacing: 25px;
      margin-bottom: 40px;
      padding-left: 25px;
    }
    span:last-child {
      padding-left: 10px;
    }
  }
  .Level {
    margin-top: 20px;

    > div:last-child {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      gap: 5px;

      div {
        flex: 1;
        height: 4px;
        background: $green25;
      }
    }
    .Selected {
      background: $green !important;
    }
  }
}
.PerfilInfo + hr {
  height: 2px;
  margin: 20px 40px;
  background: $secondary-silver;
}
.PerfilData {
  margin: 20px 0;
  padding: 0 40px 80px 40px;
  @extend .Row;
  gap: 40px;

  .icon-IdentificationCard,
  .icon-HouseLine,
  .icon-Wallet,
  .icon-Package {
    font-size: 3.75rem;
  }
  .ItemData,
  .ItemTarjeta,
  .ItemDireccion {
    padding-left: 0;
  }
  .ItemData,
  .ItemDireccion,
  .ItemTarjeta {
    margin-bottom: .625rem;
    .Selected{
      padding: 0;
    }
  }
  article {
    flex: 1;
    border: 1px solid  #F5F5F5;
    background: #F5F5F5;
    padding: .625rem;
    > b,
    > em {
      display: block;
      margin-bottom: 15px;
    }
    .grid{
      display: flex;
      align-items: center;
      gap: 10px;
      b{
        > em{
          display: block;
          font-weight: normal;
          font-size: .8125rem;
        }
      }
    }
  }
  .ButtonContent {
    margin: 15px 0 0 0;
  }
  .SeePackages{
    margin: 9.1875rem 0 0 0;
  }

}

.EditarMisDatos {
  margin: 20px 0;
  padding: 0 40px 80px 40px;

  .form-box{
    width: 100%;
    max-width: 466px;
    background-color: #F5F5F5;
    padding: 20px;
    margin: 0 auto;
  }

  .FormData{
    display: block;
    margin-bottom: 10px;
  }

  .form-row{
    display: flex;
    gap: 10px;

    .lada{
      width:8.875rem;
      select{
        font-size: 1rem;
      }
    }

    .phone-number{
      flex: 1;
    }
  }

  .ButtonLightMedium{
    display: block;
    width: 100%;
  }
  
}

.DireccionEnvio {
  margin: 20px 0;
  padding: 0 40px 80px 40px;

  .form-box{
    width: 100%;
    max-width: 466px;
    background-color: #F5F5F5;
    padding: 20px;
    margin: 0 auto;

  }

  .FormData{
    display: block;
    margin-bottom: 10px;
  }

  .form-row{
    display: flex;
    justify-content:space-between;
    
    .FormData{
      width: 49%;
    }

  }

  .ButtonLightMedium{
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .DeleteAddressButton{
    display: block;
    width: 100%;
    font-size: 14px;
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
  }
  
}



@media screen and (max-width: 768px) {
  .PerfilContent{
    .grid-title{
      display: none;
    }
  }
  .PerfilData{
    .SeePackages{
      margin: 15px 0 0 0;
    }
  }
}
/**
 *
 *
 * ? Home Statics Pages
 *
 *
*/
.Error404 {
  text-align: center;
  margin: 40px 0 240px 0;

  .icon-LinkBreak {
    font-size: 128px; /*153*/
    opacity: 0.6;
  }
  em {
    display: block;
    padding: 27px;
    margin: 15px 0;
    font-size: 209px;
  }
  h5 {
    font-weight: bold;
  }
}
.Contacto {
  article:first-child {
    padding-bottom: 33%;
    background-position: 0 35%;
  }
}
article.ContactoBox {
  background: $secondary-silver;

  h5 {
    font-weight: bold;
  }
  h5,
  label {
    display: block;
    margin-bottom: 15px;
  }
  > div {
    position: relative;
    max-width: 850px;
    margin: 0 auto;
    top: -128px;

    &:first-child {
      background: $primary-white;
      box-shadow: $ContactoBoxShadow;
      padding: 20px;
    }
    &:last-child {
      margin-top: 40px;

      b {
        display: block;
        padding-bottom: 15px;
      }
      div {
        display: flex;
        flex-direction: row;
        gap: 30px;
        justify-content: center;
      }
      i {
        font-size: 26px;
      }
    }
  }
}
.ArticleStatic {
  article:first-child {
    padding-bottom: 21%;
    background-position: 0 35%;
  }
  .title{
    padding: 0;
    border: 0 !important;
  }
}
.ArticleStaticLegal{
  article:first-child {
    height: 16.75rem;
    background-size: cover;
    background-position: center;
    // object-fit: cover;
    picture{
      position: absolute;
    }
    img{
      width: 100%;
      height: 16.75rem;
      object-fit: cover;
    }
  }
  .ImageButtonBG{
    >div{
      background: inherit;
    }
  }
}

@media screen and (max-width:700px) {
  .ArticleStatic{
    .title{
      img{
        width: 100%;

      }
    }
  }
  .ArticleStaticLegal{
    .ImageButtonBG{
      .ButtonContent {
        font-size: 1.5625rem;
        padding: .625rem;
      }
    }
  }
}

.ArticleStaticContent {
  padding: 40px 0 80px 0;

  b {
    display: block;
    padding: 10px 15px;
  }
  p {
    // padding: 10px 15px 20px 15px;
    margin-bottom: 1.25rem;
  }
  > div {
    max-width: 994px;
    margin: 0 auto;
  }
  .ArticleStaticContentText{
    padding: 0 1.25rem;
  }
}

.ArticleStaticContent,
.SobreNosotros {
  .ButtonContent {
    margin-top: 10px;
    min-width: 226px;
  }
}
.Contactanos {
  text-align: center;
  background: $secondary-silver;
  padding: 20px 0;

  b {
    padding: 0;
  }
}
.SobreNosotros {
  .ArtOnU {
    width: 206px;
    height: 97px;
  }
  .BlackImage {
    margin: 0 auto;
  }
  .Row {
    padding: 10px 15px 20px 15px;
    gap: 20px;

    &:first-child {
      > div {
        flex: 1;
      }
    }
  }
  .AboutImage {
    position: relative;
    width: 100%;
    height: 348px;
    background-size: cover;
    background-position: 0 59%;
    background-repeat: no-repeat;

    &::after {
      position: absolute;
      content: "";
      inset: 0;
      background: $GradientDark;
    }
  }
  .WeCreateToBeYourself {
    width: 398px;
    height: 35px;
    margin: -44px auto 0 auto;
    display: block;
  }
  .ButtonContent {
    border: none;
    margin-top: 20px;
  }
  p {
    margin-top: 10px;
  }
  .InstagramAbout {
    > div {
      &:first-child {
        flex: 2;
      }
      &:last-child {
        flex: 3;
      }
    }
  }
  article {
    &:first-child {
      padding-bottom: 40%;
      background-position: 0 26%;
    }
    &:last-child {
      padding: 0 40px 80px 40px;
    }
  }
  .MainBox {
    text-align: left;
  }
  .InstagramBox {
    .InstagramItem {
      width: 33.5%;
      margin-top: 21px;

      div {
        padding-bottom: 100%;
      }
      &:nth-child(2) p {
        gap: 14px;
        margin-top: 32px;
        margin-left: -30%;
        width: 160%;
      }
    }
  }
}
/**
 *
 *
 * ? Home Main Other
 *
 *
*/
input[type="radio"] {
  width: 17px;
  height: 17px;
  border: 1px solid $primary-black;
  border-radius: 16px;
  background: $primary-white;

  &:checked:after {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    background-color: $primary-black;
    border-radius: 16px;
    margin-top: 2px;
    margin-left: 2px;
  }
}
.AboutBox {
  @extend .Row;
  gap: 40px;
  padding: 40px;

  > div {
    flex: 1;
  }
}
.StrokeDash {
  position: relative;
  height: 1px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDEwdjFIMHoiLz48L3N2Zz4=");
  &::after,
  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 15px;
    height: 30px;
    background-color: $primary-white;
    top: -15px;
  }
  &::before {
    border-radius: 0 15px 15px 0;
    left: -25px;
  }
  &::after {
    border-radius: 15px 0 0 15px;
    right: -25px;
  }
}
.AboutUsBox {
  color: #000;
  border: 4px solid $secondary-black;
  padding: 15px;

  .AboutUs,
  p {
    margin-bottom: 20px;
  }
  p {
    @extend .h6;
  }
  .ButtonContent {
    font-weight: 400;
  }
}
.ArtFansBox {
  background: $secondary-black;
  color: $primary-white;
  padding: 10px 25px 20px 25px;

  .ArtFans {
    width: 145px;
    height: 42px;
    margin: 0 auto 10px auto;
  }
  ul {
    list-style: disc;
    list-style-position: inside;
    margin: 20px 0 10px 0;
  }
}
/**
 *
 *
 * ? Home Footer
 *
 *
*/
footer {
  padding: 80px 40px;
}
.FooterFirst {
  @extend .Row;
  align-items: center;
  margin-bottom: 60px;

  > div {
    flex: 1;
  }
  .ArtOnU {
    /**
    *
    * ! other reference size
    *
    */
    width: auto;
    height: 131px;
  }
}
.FooterLinks {
  display: flex;

  a {
    display: block;
    margin-top: 15px;
  }
  > div {
    @extend .Row;
    align-items: center;
    gap: 60px;
  }
}
.FooterSocial {
  @extend .Row;
  gap: 41px;
  justify-content: center;

  a {
    font-size: 29px;
  }
}


.card-loader {
  background-color: rgba(0,0,0,0.2);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08), 0 -1px 3px 0 rgba(0, 0, 0, 0.06);
  padding: 8px;
  position: relative;
  border-radius: 2px;
  margin-bottom: 0;
  height: 520px;
  overflow: hidden;
}

.card-loader:only-child {
  margin-top: 0;
}

.card-loader:before {
  content: "";
  height: 143px;
  display: block;
  background-color: rgba(0,0,0,0.2);
  box-shadow: -66px 100px 0 -68px rgba(0,0,0,0.2), -65px 130px 0 -68px rgba(0,0,0,0.2);
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
