@mixin ButtonDisplay($background, $color) {
  background: $background;
  color: $color;
}
@mixin ButtonPadding($size) {
  padding: $size;
}
.Button {
  cursor: pointer;
  text-align: center;
  border: 1px solid $primary-black;

  i {
    padding: 0 5px;
  }
}
.ButtonLight,
.ButtonLightMedium,
.ButtonLightMini,
.ButtonLightThiny {
  @extend .Button;
  @include ButtonDisplay($primary-white, $primary-black);
}
.ButtonDark,
.ButtonDarkMedium,
.ButtonDarkMini,
.ButtonDarkThiny {
  @extend .Button;
  @include ButtonDisplay($primary-black, $primary-white);
}

.ButtonLight,
.ButtonDark {
  font-size: $FontNormal;
  /*@extend .h6;*/
  @include ButtonPadding(16px);
}
.ButtonLightMini,
.ButtonDarkMini {
  @extend .FontMedium;
  @include ButtonPadding(8px);
}
.ButtonLightThiny,
.ButtonDarkThiny {
  @extend .FontSmall;
  @include ButtonPadding(4px 5px);
}
.ButtonLightMedium,
.ButtonDarkMedium {
  @extend .FontMedium;
  @include ButtonPadding(14px);
}
/*.GroupButton {}*/
.Button2 {
  display: inline-block;
  white-space: nowrap;

  i {
    padding: 0 5px;
  }
}
.ButtonLight2 {
  @extend .Button2;
  padding: 15px 40px;
  background: $primary-black-opacity40;
  color: $primary-white;
  border: 2px solid $primary-white;
}
.ButtonContent {
  display: table;
  margin: 0 auto;
  padding: 15px 20px;
}
