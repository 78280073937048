/**
 *
 *
 *light's
 *
 *
*/
$primary-white: #fff;
/*20% opacidad -> input
rgba(255, 255, 255, 0.2)
*/
$primary-white-20: #676767;
$primary-white-60: rgba(255, 255, 255, 0.6);
$silver: #dedede;
$secondary-silver: #f5f5f5;
/**
*
*
*dark's
*
*
*/
$primary-black: #121212;
$primary-black-opacity80: rgba(18, 18, 18, 0.8);
$primary-black-opacity60: rgba(18, 18, 18, 0.6);
$primary-black-opacity50: rgba(18, 18, 18, 0.5);
$primary-black-opacity40: rgba(18, 18, 18, 0.4);
$secondary-black: #28292b;
$third-black: #383939;
$gray: #555;
$secondary-gray: #818181;
/**
 *
 *
 *rainbow's
 *
 *
*/
$rose: #fc4868;
$red: #ff8c8c;
$orange: #ffca62;
$green: #76be00;
$green25: rgba(118, 191, 0, 0.25);
$blue: #2e9bff;
$purple: #b0b2f1;

/**
 *
 *
 *figma
 *
 *
  $dark: #1e1e1e;
*/

.FontColorLight {
  color: $primary-white;
}
.TextColorSec {
  color: $secondary-gray;
}
